import axios from 'axios';
import authHeader from './AuthHeader';

export default class UserService {
    getUsersSmall() {
		return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
	}

	getUsers() {
		return axios.get('/auth/usersList', { headers: authHeader() }).then(
      res => res.data.data
    );
  }

  getUsersWithOrdersSmall() {
		return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
	}

  deleteUser(id) {
    return axios.get('/auth/userDelete/'+id, { headers: authHeader() }).then(res => res.data.data);
  }

  deleteUsers(selectedUsers) {
    return axios.post('/auth/usersDelete', selectedUsers, { headers: authHeader() }).then(res => res.data.data);
  }

  createUser(user) {
    return axios.post('/auth/userCreate', user,
      { headers: authHeader() })
      .then(res => res.data.data);
  }

  editUser(user) {
    return axios.post('/auth/userEdit/'+user.id, user,
      { headers: authHeader() })
      .then(res => res.data.data);
  }

  importUsers(data) {
    return axios.post('/auth/usersImport', data,
      { headers: authHeader() })
      .then(res => res.data.data);
  }

  async checkUsersToken() {
    try{
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token) {
        let response = await fetch ('/auth/refreshToken', { headers: { Authorization: 'Bearer ' + user.token } } )
        return await (response.status == 200);
      } else {
        return false;
      }
    } catch(err){
      console.error(err);
      return false;
    }
  }

}


// import axios from 'axios';
// import authHeader from './AuthHeader';
/*
const API_URL = 'http://localhost:8002/api/test/';

export default class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }


  getUsersSmall() {
		return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
	}

	getUsers() {
		return axios.get('assets/demo/data/products.json').then(res => res.data.data);
  }

  getUsersWithOrdersSmall() {
		return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
	}

}
*/
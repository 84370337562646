<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>

				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
						</div>
					</template>

					<template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="users" v-model:selection="selectedUsers" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users" responsiveLayout="scroll">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
							<h5 class="p-mb-2 p-m-md-0">Manage Users</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id" header="id" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">id</span>
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" header="Name" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="username" header="Username" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Username</span>
							{{slotProps.data.username}}
						</template>
					</Column>
					<Column field="groups" header="Groups" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Groups</span>
							{{slotProps.data.groups}}
						</template>
					</Column>
					<Column field="email" header="Email" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Email</span>
							{{slotProps.data.email}}
						</template>
					</Column>
					<Column field="enabled" header="Enabled" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Enabled</span>
							{{slotProps.data.enabled}}
						</template>
					</Column>
					<Column field="activated" header="Activated" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Activated</span>
							{{slotProps.data.activated}}
						</template>
					</Column>

					<Column field="lastvisitdate" header="Last Visit Date" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Last Visit Date</span>
							{{slotProps.data.lastvisitdate}}
						</template>
					</Column>
					<Column field="registrationdate" header="Registration Date" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Registration Date</span>
							{{slotProps.data.registrationdate}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editUser(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteUser(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="userDialog" :style="{width: '450px'}" header="User Details" :modal="true" class="p-fluid">
					<div class="p-field">
						<label for="name">Name</label>
						<InputText id="name" v-model.trim="user.name" required="true" autofocus :class="{'p-invalid': submitted && !user.name}" />
						<small class="p-invalid" v-if="submitted && !user.name">Name is required.</small>
					</div>
					<div class="p-field">
						<label for="username">Username</label>
						<InputText id="username" v-model.trim="user.username" required="true" :class="{'p-invalid': submitted && !user.username}" />
						<small class="p-invalid" v-if="submitted && !user.username">Username is required.</small>
					</div>
					<div class="p-field">
						<label for="groups">Groups</label>
						<InputText id="groups" v-model.trim="user.groups" required="true" :class="{'p-invalid': submitted && !user.groups}" />
						<small class="p-invalid" v-if="submitted && !user.groups">Groups is required.</small>
					</div>
					<div class="p-field">
						<label for="email">Email</label>
						<InputText id="email" v-model.trim="user.email" required="true" :class="{'p-invalid': submitted && !user.email}" />
						<small class="p-invalid" v-if="submitted && !user.email">Email is required.</small>
					</div>
                    <div class="p-field-checkbox">
                        <Checkbox id="enabled" name="enabled" v-model="user.enabled" :binary="true" />
                        <label for="enabled">Enabled</label>
                    </div>
                    <div class="p-field-checkbox">
                        <Checkbox id="activated" name="activated" v-model="user.activated" :binary="true" />
                        <label for="activated">Activated</label>
                    </div>
					<div class="p-field">
						<label for="password">Password</label>
						<InputText type="password" id="password" v-model.trim="user.password" />
					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveUser" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="user">Are you sure you want to delete <b>{{user.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUserDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteUser" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="user">Are you sure you want to delete the selected users?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsersDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUsers" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import UserService from '../service/UserService';
import authHeader from '../service/AuthHeader';

export default {
	data() {
		return {
			users: null,
			userDialog: false,
			deleteUserDialog: false,
			deleteUsersDialog: false,
			user: {},
			selectedUsers: null,
			filters: {},
			submitted: false,
			statuses: [
				{label: 'INSTOCK', value: 'instock'},
				{label: 'LOWSTOCK', value: 'lowstock'},
				{label: 'OUTOFSTOCK', value: 'outofstock'}
			]
		}
	},
	userService: null,
	created() {
		this.userService = new UserService();
		this.initFilters();
	},
	mounted() {
		this.userService.getUsers().then(data => this.users = data);
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.user = {};
			this.submitted = false;
			this.userDialog = true;
		},
		hideDialog() {
			this.userDialog = false;
			this.submitted = false;
		},
		saveUser() {
			this.submitted = true;
			if ('id' in this.user) {
				this.userService.editUser(this.user).then(data => this.users = data);
				this.userDialog = false;
				this.user = {};
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Edit', life: 3000});
			} else {
				this.userService.createUser(this.user).then(data => this.users = data);
				this.userDialog = false;
				this.user = {};
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Created', life: 3000});
			}
			// this.users = this.users.filter(val => val.id !== this.user.id);
			// this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Created', life: 3000});
			/*
			if (this.user.name.trim()) {
			if (this.user.id) {
				this.user.inventoryStatus = this.user.inventoryStatus.value ? this.user.inventoryStatus.value: this.user.inventoryStatus;
				this.users[this.findIndexById(this.user.id)] = this.user;
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Updated', life: 3000});
				}
				else {
					this.user.id = this.createId();
					this.user.code = this.createId();
					this.user.image = 'user-placeholder.svg';
					this.user.inventoryStatus = this.user.inventoryStatus ? this.user.inventoryStatus.value : 'INSTOCK';
					this.users.push(this.user);
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Created', life: 3000});
				}
				this.userDialog = false;
				this.user = {};
			}
			*/
		},
		editUser(user) {
			this.user = {...user};
			this.userDialog = true;
		},
		confirmDeleteUser(user) {
			this.user = user;
			this.deleteUserDialog = true;
		},
		deleteUser() {
			this.userService.deleteUser(this.user.id).then(data => this.users = data);
			// this.userService.deleteUser(this.user.id).then(data => this.users = data);
			// Hibakezelés hiányzik!
			this.users = this.users.filter(val => val.id !== this.user.id);
			this.deleteUserDialog = false;
			this.user = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Deleted', life: 3000});
			// this.userService.getUsers().then(data => this.users = data);
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		/*
		importCSV(ev) {
			// const file = ev.target.files[0]
		
			this.userService.getUsers().then(data => this.users = data);
			this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded and imported', life: 3000});
			
		},
		*/
		
		prepRequestImport(ev) {
			ev.xhr.setRequestHeader (authHeader)
			// Authorization: 'Bearer ' + user.accessToken
			// https://forum.primefaces.org/viewtopic.php?t=47614
			ev.xhr.withCredentials = true;
		},
		confirmDeleteSelected() {
			this.deleteUsersDialog = true;
		},
		deleteSelectedUsers() {
			//			this.users = this.users.filter(val => !this.selectedUsers.includes(val));
			this.userService.deleteUsers(this.selectedUsers).then(data => this.users = data);
			this.deleteUsersDialog = false;
			this.selectedUsers = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Users Deleted', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.user-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-toolbar) {
		flex-wrap: wrap;
		
		.p-button {
			margin-bottom: 0.25rem;
		}
	}
}
</style>
